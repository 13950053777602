import { AsyncAction } from './AsyncAction';
import { animationFrameProvider } from './animationFrameProvider';
export class AnimationFrameAction extends AsyncAction {
  constructor(scheduler, work) {
    super(scheduler, work);
    this.scheduler = scheduler;
    this.work = work;
  }
  requestAsyncId(scheduler, id, delay = 0) {
    if (delay !== null && delay > 0) {
      return super.requestAsyncId(scheduler, id, delay);
    }
    scheduler.actions.push(this);
    return scheduler._scheduled || (scheduler._scheduled = animationFrameProvider.requestAnimationFrame(() => scheduler.flush(undefined)));
  }
  recycleAsyncId(scheduler, id, delay = 0) {
    if (delay != null && delay > 0 || delay == null && this.delay > 0) {
      return super.recycleAsyncId(scheduler, id, delay);
    }
    if (!scheduler.actions.some(action => action.id === id)) {
      animationFrameProvider.cancelAnimationFrame(id);
      scheduler._scheduled = undefined;
    }
    return undefined;
  }
}
